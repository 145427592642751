<template>
  <div>
    <div class="uk-padding-small">
      <vk-breadcrumb class="uk-margin-remove">
        <vk-breadcrumb-item :disabled="true">Utilisateurs</vk-breadcrumb-item>
      </vk-breadcrumb>
    </div>
    <div class="uk-padding-small">
      <vue-good-table
          :is-loading="usersListIsUpdating"
          :columns="columns"
          :rows="usersList"
          :search-options="{
            enabled: true,
          }"
          :pagination-options="pagination"
          styleClass="vgt-table striped bordered condensed"
      >
        <template v-slot:table-row="props">
          <div v-if="props.column.field === 'user'">
            <div>
              Identifiant : {{ props.row.username }}
            </div>
            <div>
              Nom : {{ props.row.userInfos.lastName }}
            </div>
            <div>
              Prénom : {{ props.row.userInfos.firstName }}
            </div>
          </div>
          <div v-else-if="props.column.field === 'actions'" class="text-center">
            <button
                type="button"
                class="btn btn-primary"
                @click="$router.push({name: 'user_edit', params: {userId: props.row.id}})"
            >
              <font-awesome-icon icon="fa-regular fa-eye" />
            </button>
          </div>
          <div v-else>
            {{ props.formattedRow[props.column.field] }}
          </div>
        </template>
      </vue-good-table>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import {mapState} from "vuex";

export default {
  name: "Users",
  data: () => ({
    columns: [
      {
        label: 'Utilisateur',
        field: 'user',
      },
      {
        label: 'Actions',
        field: 'actions',
        width: '1%',
      }
    ],
    pagination: {
      enabled: true,
      mode: 'records',
      perPage: 10,
      position: 'top',
      perPageDropdown: [10, 25, 50],
      dropdownAllowAll: false,
      setCurrentPage: 1,
      jumpFirstOrLast : true,
      firstLabel : 'Début',
      lastLabel : 'Fin',
      nextLabel: 'Suiv.',
      prevLabel: 'Préc.',
      rowsPerPageLabel: 'Lignes par page ',
      ofLabel: 'de',
      pageLabel: 'page',
      allLabel: 'Tous',
      // infoFn: (params) => `Page ${params.currentPage}`,
    },
  }),
  computed: {
    ...mapState('users', {usersList: 'list', usersListIsUpdating: 'listIsUpdating'})
  },
  beforeRouteEnter(to, from, next) {
    store.dispatch('users/updateUsersList')
    next()
  },
  beforeRouteUpdate(to, from, next) {
    store.dispatch('users/updateUsersList')
    next()
  },
}
</script>

<style scoped>

</style>
